import "../App.css";
import React from "react";

import Iframe from "./Iframe";

function Home() {
  return (
    <div className="App">
      <div className="header">
        <Iframe
          title="loading"
          src="https://giphy.com/embed/LRHOa06FCrQ9JRLl7j"
        />
        <h3>Find Iftar Timer at our new domain:</h3>
        <h3>
          <a
            href="https://iftartimer.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.iftartimer.com
          </a>
        </h3>
      </div>
      <div className="footer">
        <h1>
          Made by{" "}
          <a
            href="https://github.com/tahsinocity/iftar-timer"
            target="_blank"
            rel="noopener noreferrer"
          >
            @tahsinocity
          </a>{" "}
        </h1>
      </div>
    </div>
  );
}
export default Home;