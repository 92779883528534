import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(
    JSON.parse(localStorage.getItem("isSignedIn")) || false
  );

  useEffect(() => {
    const initClient = () => {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
            scope: SCOPES,
          })
          .then(() => {
            const authInstance = gapi.auth2.getAuthInstance();
            const signedIn = authInstance.isSignedIn.get();

            setIsSignedIn(signedIn);
            localStorage.setItem("isSignedIn", JSON.stringify(signedIn));

            authInstance.isSignedIn.listen((signedIn) => {
              setIsSignedIn(signedIn);
              localStorage.setItem("isSignedIn", JSON.stringify(signedIn));
            });

            if (signedIn) {
              listEvents();
            }
          })
          .catch((error) => console.error("Google API init error:", error));
      });
    };

    initClient();
  }, []);

  const handleSignIn = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    if (authInstance) {
      authInstance.signIn().then(() => {
        setIsSignedIn(true);
        localStorage.setItem("isSignedIn", JSON.stringify(true));
        listEvents();
      });
    } else {
      console.error("Auth instance not found.");
    }
  };

  const handleSignOut = () => {
    const authInstance = gapi.auth2.getAuthInstance();
    if (authInstance) {
      authInstance.signOut().then(() => {
        setIsSignedIn(false);
        setEvents([]);
        localStorage.setItem("isSignedIn", JSON.stringify(false));
      });
    }
  };

  const listEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      })
      .then((response) => {
        setEvents(response.result.items || []);
      })
      .catch((error) => console.error("Error fetching calendar events:", error));
  };

  console.log(events);

  return (
    <div className="App">
      <h2>Upcoming Events</h2>
      {!isSignedIn ? (
        <button className="sign-in-button" onClick={handleSignIn}>Sign in with Google</button>
      ) : (
        <>
          <ul>
            {events.length > 0 ? (
              events.map((event) => (
                <div className="events" key={event.id}>
                  <div>{event.summary}</div>
                  <div>
                    {new Date(event.start.dateTime || event.start.date).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </div>
                  {event.location && <div className="location">{event.location}</div>}
                </div>
              ))
            ) : (
              <p>No upcoming events found.</p>
            )}
          </ul>
          <button className="sign-out-button" onClick={handleSignOut}>Sign out</button>
        </>
      )}
    </div>
  );
};

export default Events;
